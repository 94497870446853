import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Logo from '../icons/logo.svg'
import TickerTapeFooter from '../ticker-tape-footer'
import Login from './login'
import Navigation from './navigation'
import Offices from './offices'
import Partners from './partners'
import Social from './social'
import SubBrands from './sub-brands'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Footer = ({ siteTitle }) => {
  const staticData = useStaticQuery(graphql`
    query MainFooterQuery {
      footer: prismicMainFooter {
        ...MainFooter
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const data = useMergePrismicPreviewData(staticData)
  const isMobile = useIsMobile()

  /** @type {MainFooter} footer */
  const footer = data.footer
  if (!footer) {
    return null
  }

  /** @type {SiteConfig} config */
  const config = data?.config
  if (!config) {
    return null
  }

  return (
    <>
      <TickerTapeFooter text={'get in touch'} background="navy" />
      <footer className="footer">
        <div className="footer__container container container--flex">
          {!isMobile && (
            <div className="footer__column">
              <div className="footer__logo">
                <Logo />
              </div>

              {config && <Social config={config} />}
            </div>
          )}

          <div className="footer__column offices">
            <Offices offices={data.footer.data.group_offices} />
          </div>

          <div className="footer__column">
            <Login />
          </div>
        </div>

        <div className="footer__container container container--flex">
          <div className="footer__column">
            {/* <Partners /> */}
          </div>

          {!isMobile && (
            <div className="footer__column">
              <SubBrands brands={footer.data.sub_brands} />
            </div>
          )}

          {isMobile && (
            <div className="footer__column">
              <div className="footer__logo">
                <Logo />
              </div>

              {config && <Social config={config} />}
            </div>
          )}
        </div>

        <div className="footer__container container container--flex">
          <div className="footer__column">
            <Navigation navItems={data.footer.data.navigation_items} />
          </div>

          <div className="footer__column">
            <ul className="footer-registrations">
              <li className="footer-registrations__item">
                Company registration: 12357553
              </li>
              <li className="footer-registrations__item">
                VAT number: 365075881
              </li>
            </ul>
          </div>
        </div>

        <div className="footer__container container container--flex">
          <div className="footer__column">
            <div className="footer__credit">
              <div className="footer__copyright">
                <span className="screenreader-text">Copyright </span>
                &copy;{new Date().getFullYear()} {siteTitle}.
              </div>

              <div className="footer__attribution">
                Website by{' '}
                <a
                  href="https://www.superrb.com"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  title="Website by Superrb"
                >
                  Superrb
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
